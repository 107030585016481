import { Flex } from '@chakra-ui/react';
import colors from 'src/theme/colors';
import { useEpisodesPageContext } from '../../EpisodesPageContext';
import FilterButton from './FilterButton';
import { useFetchEpisodes } from '../../hooks/useFetchEpisodes';

export default function EpisodesFilter() {
  const [{ filter }, dispatch] = useEpisodesPageContext();
  const { filterCount } = useFetchEpisodes();

  function onSetFilter(filterType: typeof filter.type) {
    dispatch({ type: 'set-filter', filter: { ...filter, isDraft: undefined, type: filterType } });
  }

  return (
    <Flex
      borderTop={`1px solid ${colors.podcastShade200}`}
      w="100%"
      gridColumn="span 2"
      gridGap="30px"
    >
      <FilterButton
        text="all episodes"
        count={filterCount?.all}
        isSelected={filter.type === undefined && filter.isDraft === undefined}
        onClick={() => onSetFilter(undefined)}
      />
      <FilterButton
        text="drafts"
        count={filterCount?.draft}
        isSelected={filter.isDraft === true}
        onClick={() =>
          dispatch({
            type: 'set-filter',
            filter: { ...filter, type: undefined, isDraft: true },
          })
        }
      />
      <FilterButton
        text="processing"
        count={filterCount?.processing}
        isSelected={filter.type === 'processing'}
        onClick={() => onSetFilter('processing')}
      />
      <FilterButton
        text="processed"
        count={filterCount?.complete}
        isSelected={filter.type === 'complete'}
        onClick={() => onSetFilter('complete')}
      />
    </Flex>
  );
}
