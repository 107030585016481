const toTwoDecimalsFromAsset = (asset: { duration: number }) =>
  Math.round(parseFloat(String(asset.duration)) * 100) / 100;

const toTwoDecimalsFromNumber = (duration: number) =>
  Math.round(parseFloat(String(duration)) * 100) / 100;

const toThreeDecimalsFromNumber = (duration: number) =>
  Math.round(parseFloat(String(duration)) * 1000) / 1000;

function toMinutesAndSeconds(durationInSeconds: number) {
  if (durationInSeconds !== 0 && !durationInSeconds) return '';
  const negativeSign = durationInSeconds < 0 ? '-' : '';
  const positiveDurationInSeconds = Math.abs(durationInSeconds);
  const minutes = Number(positiveDurationInSeconds) / 60;
  const getMinutesPart = (min: number) => String(Math.floor(min)).padStart(2, '0');
  const seconds = (minutes - Number(getMinutesPart(minutes))) * 60;
  const secondsPart = String(Math.floor(Number(seconds.toFixed(2)))).padStart(2, '0');
  if (minutes >= 60) {
    const hoursPart = String(Math.floor(minutes / 60)).padStart(2, '0');
    return `${negativeSign}${hoursPart}:${getMinutesPart(minutes % 60)}:${secondsPart}`;
  }
  return `${negativeSign}${getMinutesPart(minutes)}:${secondsPart}`;
}

function toHumanReadableWithDecimal(durationInSeconds: number) {
  if (Math.abs(durationInSeconds) < 10) {
    return `${durationInSeconds.toFixed(1)}s`;
  }
  return toHumanReadable(durationInSeconds);
}

function toHumanReadable(durationInSeconds: number) {
  if (durationInSeconds === 0) {
    return '';
  }

  const negativeSign = durationInSeconds < 0 ? '-' : '';
  const positiveDurationInSeconds = Math.abs(durationInSeconds);
  const parsed = toMinutesAndSeconds(positiveDurationInSeconds).split(':').map(Number);
  function getValue(val: number, indicator: 'h' | 'm' | 's') {
    return val > 0 ? `${val}${indicator}` : '';
  }
  switch (parsed.length) {
    case 2: {
      const [min, sec] = parsed;
      return min === 0
        ? `${negativeSign}${getValue(sec, 's')}`
        : `${negativeSign}${getValue(min, 'm')} ${getValue(sec, 's')}`.trim();
    }
    case 3: {
      const [hour, min, sec] = parsed;
      return `${negativeSign}${getValue(hour, 'h')} ${getValue(min, 'm')} ${getValue(
        sec,
        's',
      )}`.trim();
    }
    default: {
      return '';
    }
  }
}

/**
 * Displays approximate human readable duration in sentence form. E.g
 * 0 -> '0 seconds'
 * 100 -> '1 minute 40 seconds'
 * 1000 -> '16 minutes'
 * 3600 -> '1 hour'
 * 1000 -> '2 hours 46 minutes'
 * @param durationInSeconds
 * @returns
 */
function toApproxSentence(durationInSeconds: number) {
  if (durationInSeconds === 0) {
    return '0 seconds';
  }
  const negativeSign = durationInSeconds < 0 ? '-' : '';
  const positiveDurationInSeconds = Math.abs(durationInSeconds);
  const parsed = toMinutesAndSeconds(positiveDurationInSeconds).split(':').map(Number);
  function getPlural(val: number, indicator: 'hour' | 'min' | 'minute' | 'second') {
    return val === 1 ? `${val} ${indicator}` : `${val} ${indicator}s`;
  }
  switch (parsed.length) {
    case 2: {
      const [min, sec] = parsed;
      if (min >= 5) {
        return `${negativeSign}${getPlural(min, 'min')}`;
      }

      if (min === 0) {
        return `${negativeSign}${getPlural(sec, 'second')}`;
      }

      if (sec === 0) {
        return `${negativeSign}${getPlural(min, 'min')}`;
      }
      return `${negativeSign}${getPlural(min, 'min')} ${getPlural(sec, 'second')}`.trim();
    }
    case 3: {
      const [hour, min] = parsed;
      if (min === 0) {
        return `${negativeSign}${getPlural(hour, 'hour')}`;
      }
      return `${negativeSign}${getPlural(hour, 'hour')} ${getPlural(min, 'min')}`.trim();
    }
    default: {
      return '';
    }
  }
}

export default {
  toMinutesAndSeconds,
  toTwoDecimalsFromAsset,
  toTwoDecimalsFromNumber,
  toThreeDecimalsFromNumber,
  toHumanReadable,
  toHumanReadableWithDecimal,
  toApproxSentence,
};
