import { createContext, ReactNode, useContext, useMemo, useEffect, useState } from 'react';
import { useUserCache } from 'src/apollo/cache';
import { Permissions, Tier, usePermissionsQuery } from 'src/generated/graphql';
import assert from 'src/utils/assert';

interface PermissionsState {
  permissions?: Permissions | null;
  refetchPermissions: () => void;
  startPolling: (intervalMs: number) => void;
  stopPolling: () => void;
  pollQuicklyUntilCallsFinished: () => void;
  setTotalUploadDuration: (duration: number) => void;
  isCardlessTrial: Boolean;
}

const PermissionsContext = createContext<PermissionsState | undefined>(undefined);

export function usePermissionsContext() {
  const context = useContext(PermissionsContext);
  assert(
    context,
    'permissions context must be used as a child of the permissions context provider',
  );
  return context;
}

export function PermissionsContextProvider({ children }: { children: ReactNode }) {
  const user = useUserCache();
  const [totalUploadDuration, setTotalUploadDuration] = useState(0);
  const [isManuallyPolling, setIsManuallyPolling] = useState(false);
  const [isQuicklyPolling, setIsQuicklyPolling] = useState(false);

  const {
    refetch,
    data,
    startPolling: startPollingInternal,
    stopPolling: stopPollingInternal,
  } = usePermissionsQuery({
    fetchPolicy: 'cache-and-network',
  });

  // refetch permissions if user logs in or out
  useEffect(() => {
    refetch();
  }, [user, refetch]);

  useEffect(() => {
    if (data?.permissions) {
      if (data.permissions.audioProcessingRemainingValueIsChanging) {
        // If the remaining value is changing, we want to poll for updates every minute or 10 seconds
        let pollInterval = 10000;
        if (isQuicklyPolling) {
          pollInterval = 1000;
        } else if (data.permissions.audioProcessingRemainingSeconds ?? 0 > 60) {
          pollInterval = 60000;
        }
        startPollingInternal(pollInterval);
      } else if (!isManuallyPolling) {
        stopPollingInternal();
        setIsQuicklyPolling(false);
      }
    }
    return () => {
      stopPollingInternal();
    };
  }, [data, isManuallyPolling, isQuicklyPolling, startPollingInternal, stopPollingInternal]);

  // Disable lint warning, as it doesn't seem to poll unless page reloads. TOOD: investigate why
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const startPolling = (pollInterval: number) => {
    setIsManuallyPolling(true);
    startPollingInternal(pollInterval);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stopPolling = () => {
    setIsManuallyPolling(false);
    setIsQuicklyPolling(false);
    stopPollingInternal();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pollQuicklyUntilCallsFinished = () => {
    setIsManuallyPolling(false);
    setIsQuicklyPolling(true);
    startPollingInternal(1000);
  };

  const state = useMemo(() => {
    if (data?.permissions) {
      const permissions = { ...data.permissions }; // make a copy as we will modify attributes.
      if (permissions && totalUploadDuration > 0 && permissions.audioProcessingRemainingSeconds) {
        permissions.audioProcessingRemainingSeconds -= totalUploadDuration;
        permissions.audioProcessingLimitReached = permissions.audioProcessingRemainingSeconds <= 0;
      }
      return {
        permissions,
        refetchPermissions: refetch,
        startPolling,
        stopPolling,
        pollQuicklyUntilCallsFinished,
        setTotalUploadDuration,
        isCardlessTrial: !!(permissions?.tier === Tier.TrialWithoutCard),
      };
    }

    return {
      permissions: undefined,
      refetchPermissions: refetch,
      startPolling,
      stopPolling,
      setTotalUploadDuration,
      pollQuicklyUntilCallsFinished,
      isCardlessTrial: false,
    };
  }, [
    data?.permissions,
    pollQuicklyUntilCallsFinished,
    refetch,
    startPolling,
    stopPolling,
    totalUploadDuration,
  ]);

  return <PermissionsContext.Provider value={state}>{children}</PermissionsContext.Provider>;
}
