import { HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import colors from 'src/theme/colors';
import { fonts } from 'src/styles/variables';

function AdminNav() {
  return (
    <HStack>
      <Link to="/admin" style={adminLinkStyle}>
        Users
      </Link>
      <Link to="/admin/free-library" style={adminLinkStyle}>
        Free Library
      </Link>
      <Link to="/admin/jobs" style={adminLinkStyle}>
        Jobs
      </Link>
      <Link to="/admin/hosted-shows" style={adminLinkStyle}>
        Hosted Shows
      </Link>
      <Link to="/admin/hosting-limit-offenders" style={adminLinkStyle}>
        Hosting Limit Offenders
      </Link>
    </HStack>
  );
}

export default AdminNav;

const adminLinkStyle = {
  color: colors.podcastShade400,
  fontFamily: fonts.bold,
  textDecoration: 'none',
  display: 'inline-block',
  padding: '15px 20px',
};
