import { useEffect } from 'react';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import { useEpisodesQuery } from 'src/generated/graphql';

export default function useCanCreateEpisode() {
  const episodesCache = useEpisodesQuery({
    fetchPolicy: 'cache-only',
    variables: { limit: 10, offset: 0, name: '', order: 'dateDesc' },
  });

  const { permissions, refetchPermissions } = usePermissionsContext();

  useEffect(() => {
    if (!permissions) {
      refetchPermissions();
    }
  }, [permissions, refetchPermissions]);

  const numEpisodes = episodesCache.data?.filteredTotal ?? 0;
  const episodeLimit =
    permissions?.episodeLimit === null ? Infinity : permissions?.episodeLimit ?? 0;
  return permissions && numEpisodes < episodeLimit;
}
