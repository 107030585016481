import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import PadlockClosed from 'src/assets/icons/PadlockClosed';
import hexToRgb from 'src/utils/hexToRgb';

function CircularUsage({ color, percent }: { color: string; percent: number }) {
  return (
    <CircularProgress
      value={percent}
      size="36px"
      color={color}
      trackColor={hexToRgb(color, 0.3)}
      thickness="12px"
      capIsRound
    >
      <CircularProgressLabel width="unset" mb="2px">
        <PadlockClosed color={color} />
      </CircularProgressLabel>
    </CircularProgress>
  );
}

export default CircularUsage;
