import {
  Box,
  Button as ChakraButton,
  ButtonProps,
  CircularProgress,
  Flex,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MouseEventHandler, ReactNode } from 'react';
import { colours, fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import hexToRgb from 'src/utils/hexToRgb';
import { MyShowsContextProvider } from 'src/components/my-shows/MyShowsContext';
import { useCreateEpisode } from 'src/pages/episodes/top-section/TopSection';
import useCanCreateEpisode from 'src/pages/create-episode/hooks/useCanCreateEpisode';
import UpgradeSubscriptionModal from 'src/pages/episodes/UpgradeSubscriptionModal';
import usePageSelected from '../../hooks/usePageSelected';
import Icons from '../../Icons';
import { useMenuContext } from '../../MenuContext';
import MyShowsMenuButton from './MyShowsMenuButton';

interface NavButtonProps extends ButtonProps {
  page:
    | 'episodes'
    | 'call-recorder'
    | 'clip-genie'
    | 'library'
    | 'settings'
    | 'help-center'
    | 'account'
    | 'statistics'
    | 'upgrade'
    | 'my-shows';
}

export default function NavButton({ page, ...props }: NavButtonProps) {
  const [menuState] = useMenuContext();
  const selected = usePageSelected();

  switch (page) {
    case 'episodes': {
      return (
        <Box position="relative">
          <Button
            text="my episodes"
            Icon={
              <Icons.Episodes
                color={selected.episodes === true ? 'podcastPurple800' : colours.white}
              />
            }
            isSelected={selected.episodes}
            to="/episodes"
            {...props}
          />
          {menuState === 'open' && <CreateEpisodeButton isSelected={selected.episodes} />}
        </Box>
      );
    }
    case 'call-recorder': {
      return (
        <Box position="relative">
          <Button
            text="record"
            Icon={
              <Icons.Recorder color={selected.callRecorder ? 'podcastPurple800' : colours.white} />
            }
            isSelected={selected.callRecorder}
            to="/record-call"
            {...props}
          />
        </Box>
      );
    }
    case 'clip-genie': {
      return (
        <Button
          text="Clip Genie"
          Icon={
            <Icons.ClipGenie
              color={selected.clipGenie === true ? 'podcastPurple800' : colours.white}
            />
          }
          isSelected={selected.clipGenie}
          to="/clip-genie"
          {...props}
        />
      );
    }
    case 'library': {
      return (
        <Button
          text="my library"
          Icon={
            <Icons.Library color={selected.library === true ? 'podcastPurple800' : colours.white} />
          }
          isSelected={selected.library}
          to="/library"
          {...props}
        />
      );
    }
    case 'statistics': {
      return (
        <Button
          text="Statistics"
          Icon={
            <Icons.Statistics
              color={selected.statistics === true ? 'podcastPurple800' : colours.white}
            />
          }
          isSelected={selected.statistics}
          to="/statistics"
          {...props}
        />
      );
    }
    case 'settings': {
      return (
        <Button
          text="show settings"
          Icon={
            <Icons.Settings
              color={selected.settings === true ? 'podcastPurple800' : colours.white}
            />
          }
          isSelected={selected.settings}
          to="/settings"
          {...props}
        />
      );
    }
    case 'help-center': {
      return (
        <Button
          text="help center"
          Icon={<Icons.Question />}
          isSelected={false}
          as="a"
          // @ts-ignore
          href="https://help.alitu.com/en"
          target="_blank"
          to=""
          {...props}
        />
      );
    }
    case 'upgrade': {
      const icon =
        menuState === 'open' ? <Icons.Account /> : <CircularProgress size="24px" value={80} />;
      return (
        <Button text="upgrade" Icon={icon} isSelected={selected.upgrade} to="/upgrade" {...props} />
      );
    }
    case 'my-shows': {
      return (
        <MyShowsContextProvider>
          <MyShowsMenuButton menuState={menuState} />
        </MyShowsContextProvider>
      );
    }
    default: {
      throw new Error(`Unhandled nav button ${page}`);
    }
  }
}

function Button({
  text,
  Icon,
  isSelected,
  ...props
}: ButtonProps & {
  to?: string;
  text: string;
  Icon: ReactNode;
  isSelected: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) {
  const [menuState] = useMenuContext();
  const sharedProps =
    isSelected === true
      ? {
          bg: colours.white,
          color: colors.podcastPurple800,
          borderRadius: '12px',
          _hover: {
            bg: colours.white,
            boxShadow: '0px 2px 6px rgb(101 85 205 / 30%)',
          },
          _active: { bg: colours.white, color: hexToRgb(colors.podcastPurple800, 0.8) },
        }
      : {
          bg: 'transparent',
          color: colors.podcastShade100,
          _hover: {
            bg: menuState === 'closed' ? colors.podcastPurple800 : hexToRgb(colours.white, 0.1),
          },
          _active: { color: hexToRgb(colors.podcastShade200, 0.8) },
        };

  if (menuState === 'closed') {
    return (
      <OpenMenuButton
        as={Link}
        variant="flat"
        w="48px"
        height="48px"
        aria-label={text}
        Icon={Icon}
        fontSize="0px" // hide text when the menu is closed and user is not hovering
        {...sharedProps}
        {...props}
        _hover={{
          ...props._hover,
          ...sharedProps._hover,
          fontSize: '16px', // show text on hover
          w: '100%',
          justifySelf: 'flex-start',
          padding: props._hover?.padding || '0 10px',
        }}
      >
        {text}
      </OpenMenuButton>
    );
  }

  return (
    <OpenMenuButton aria-label={text} Icon={Icon} {...sharedProps} {...props} fontSize="16px">
      {text}
    </OpenMenuButton>
  );
}

function OpenMenuButton({
  Icon,
  children,
  to,
  onClick,
  ...props
}: ButtonProps & { Icon: ReactNode; to?: string }) {
  return (
    <ChakraButton
      as={Link}
      to={to}
      onClick={onClick}
      variant="flat"
      fontFamily={fonts.bold}
      justifyContent="flex-start"
      textTransform="capitalize"
      w="100%"
      height="48px"
      px="15px"
      transitionDuration="150ms"
      zIndex={20}
      justifySelf="flex-start"
      alignItems="center"
      gridGap="12px"
      {...props}
    >
      {/* Icon wrapper with fixed width prevents 'jumping' icons on hover */}
      <Flex w="28px" justifyContent="center">
        {Icon}
      </Flex>
      {children}
    </ChakraButton>
  );
}

function CreateEpisodeButton({ isSelected }: { isSelected: boolean }) {
  const canCreateEpisode = useCanCreateEpisode();
  const createEpisode = useCreateEpisode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <UpgradeSubscriptionModal isOpen={isOpen} onClose={onClose} />
      <IconButton
        onClick={() => {
          if (!canCreateEpisode) {
            onOpen();
            return;
          }
          createEpisode();
        }}
        aria-label="create episode"
        borderRadius="8px"
        minWidth="30px"
        height="30px"
        bg="rgba(0, 0, 0, 0.2)"
        bgGradient="none"
        boxShadow="none"
        bgColor={isSelected === true ? colors.podcastPurple : hexToRgb(colours.black, 0.2)}
        position="absolute"
        right="9px"
        top="9px"
        zIndex={21}
      >
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 6h10M6 1v10"
            stroke={colours.white}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </IconButton>
    </>
  );
}
