import { FlexProps } from '@chakra-ui/react';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import { Tier } from 'src/generated/graphql';
import useFeatureFlag from 'src/components/feature-flags/useFeatureFlag';
import { useMenuContext } from '../MenuContext';
import NavButton from './components/NavButton';
import NavButtonsContainer from './components/NavButtonsContainer';
import UpgradeCta from './UpgradeCta';

const buttonContainerProps = {
  position: 'absolute',
} as Partial<FlexProps>;

export function NavContainer() {
  const [menuState] = useMenuContext();
  const { permissions } = usePermissionsContext();
  const isMyShowsEnabled = useFeatureFlag('my-shows');
  const showUpgradeCta =
    permissions?.tier === Tier.TrialWithoutCard ||
    permissions?.tier === Tier.TrialWithoutCardExpired;

  return menuState === 'open' ? (
    <NavButtonsContainer mb="12px">
      <NavButton page="settings" />
      <NavButton page="help-center" />
      {isMyShowsEnabled && <NavButton page="my-shows" />}
      {showUpgradeCta && <UpgradeCta />}
    </NavButtonsContainer>
  ) : (
    <NavButtonsContainer {...buttonContainerProps} bottom="110px">
      <NavButton page="settings" px="13px" />
      <NavButton page="help-center" px="13px" />
      {isMyShowsEnabled && <NavButton page="my-shows" px="13px" />}
      {showUpgradeCta && <UpgradeCta />}
    </NavButtonsContainer>
  );
}
