import { Box, Button, Text, Center, HStack, useBoolean } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import PadlockOpen from 'src/assets/icons/PadlockOpen';
import CircularUsage from 'src/components/permissions/CircularUsage';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import { fonts } from 'src/styles/variables';
import colors from 'src/theme/colors';
import hexToRgb from 'src/utils/hexToRgb';
import parseDuration from 'src/utils/parseDuration';
import RightArrow from 'src/assets/icons/RightArrow';
import usePageSelected from '../hooks/usePageSelected';
import { useMenuContext } from '../MenuContext';

function UpgradeCta() {
  const { permissions } = usePermissionsContext();

  const remainingPercent =
    permissions &&
    permissions.audioProcessingRemainingSeconds &&
    permissions.audioProcessingQuotaSeconds
      ? Math.max(0, 100 * permissions.audioProcessingRemainingSeconds) /
        permissions.audioProcessingQuotaSeconds
      : 0;

  const [menuState] = useMenuContext();
  const { upgrade: isSelected } = usePageSelected();
  const [isHovering, setHovering] = useBoolean(false);

  const isOpen = menuState === 'open';
  const invertColors = isSelected || (isOpen && isHovering);

  let bg;
  if (invertColors) {
    bg = 'white';
  } else if (isOpen) {
    bg = hexToRgb(colors.white, 0.1);
  } else {
    bg = isHovering ? colors.podcastPurple800 : 'transparent';
  }

  const showUpgradeText = (isOpen && isHovering) || permissions?.audioProcessingLimitReached;

  return (
    <Button
      as={Link}
      to="/upgrade"
      bg={bg}
      variant="flat"
      fontFamily={fonts.bold}
      justifyContent="flex-start"
      textDecoration="none"
      textTransform="none"
      w={!isOpen && !isHovering ? '48px' : '100%'}
      height="54px"
      pl={isOpen ? '12px' : '6px'}
      pr={isOpen || (!isOpen && isHovering) ? '12px' : '6px'}
      py={isOpen || (!isOpen && isHovering) ? '9px' : '6px'}
      transitionDuration="150ms"
      zIndex={20}
      justifySelf="flex-start"
      alignItems="center"
      gridGap="12px"
      onMouseEnter={setHovering.on}
      onMouseLeave={setHovering.off}
      _hover={{
        boxShadow: isSelected ? '0px 2px 6px rgb(101 85 205 / 30%)' : 'none',
      }}
    >
      <HStack alignItems="center" spacing="10px">
        <UsageIcon
          invertColors={invertColors}
          showUnlock={isOpen && isHovering}
          remainingPercent={remainingPercent}
        />
        {(isOpen || isHovering) && (
          <Box>
            <Text
              fontSize="16px"
              fontWeight="600"
              color={invertColors ? 'podcastPurple800' : 'white'}
            >
              {showUpgradeText
                ? 'Upgrade'
                : parseDuration.toApproxSentence(permissions?.audioProcessingRemainingSeconds || 0)}
            </Text>
            <Text
              fontSize="11px"
              fontWeight="600"
              color={invertColors ? 'podcastPurple' : hexToRgb(colors.white, 0.8)}
            >
              {showUpgradeText ? 'for unlimited minutes' : 'of audio cleanup left'}
            </Text>
          </Box>
        )}
        {!isOpen && isHovering && (
          <Button
            variant="flat"
            bg={invertColors ? 'podcastPurple' : 'white'}
            color={invertColors ? 'white' : 'podcastPurple'}
            textTransform="none"
            _hover={{ bg: invertColors ? 'podcastPurple800' : 'white' }}
            height="36px"
            rightIcon={<RightArrow color={invertColors ? 'white' : 'podcastPurple'} />}
          >
            Upgrade
          </Button>
        )}
      </HStack>
    </Button>
  );
}

function UsageIcon({
  showUnlock,
  invertColors,
  remainingPercent,
}: {
  showUnlock: boolean;
  invertColors: boolean;
  remainingPercent: number;
}) {
  const color = invertColors ? colors.podcastPurple : colors.white;
  return (
    <Box>
      {showUnlock ? (
        <Center w="36px" h="36px" borderRadius="18px" bg={color}>
          <PadlockOpen color={invertColors ? colors.white : colors.podcastPurple} />
        </Center>
      ) : (
        <CircularUsage color={color} percent={remainingPercent} />
      )}
    </Box>
  );
}

export default UpgradeCta;
