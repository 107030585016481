import {
  Episode,
  useGetSubscriptionQuery,
  useShowQuery,
  useUserQuery,
} from 'src/generated/graphql';
import useFetchEpisode from 'src/pages/episode-builder/hooks/useFetchEpisode';

export function useSubscriptionCache() {
  try {
    const { data: cache } = useGetSubscriptionQuery({
      fetchPolicy: 'cache-only',
    });

    if (!cache) {
      return;
    }

    return cache.subscription;
  } catch (error) {
    throw new Error(`Cannot find subscription - ${error}`);
  }
}

export function useUserCache() {
  try {
    const { data: cache } = useUserQuery({ fetchPolicy: 'cache-only' });

    if (!cache) {
      return;
    }

    return cache.user;
  } catch (error) {
    throw new Error(`Cannot find user - ${error}`);
  }
}

export function useShowCache() {
  try {
    // NOTE: I think there is no case where userCache would be undefined when show cache is required
    // therefore it's safe to get user from cache here
    const user = useUserCache();
    const { data: cache } = useShowQuery({
      fetchPolicy: 'cache-only',
      variables: { id: user?.activeProjectId ?? 'none' },
    });

    if (!cache) {
      return;
    }

    return cache.show;
  } catch (error) {
    throw new Error(`Cannot find show - ${error}`);
  }
}

/**
 *
 * @module useEpisodeBuilderCache
 * This hook is can be used in any child component of the episode builder component.
 */
export function useEpisodeBuilderCache(): Omit<Episode, 'logs'> {
  try {
    const { episode } = useFetchEpisode();
    if (!episode) {
      throw new Error();
    }
    return episode;
  } catch (error) {
    throw new Error(`Cannot find episode details - ${error}`);
  }
}
