import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlusIcon from 'src/assets/icons/Plus';
import Help from 'src/components/menu/Help';
import { PageHeaderContainer, PageHeading } from 'src/components/page/PageHeader';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import useMobileLayout from 'src/hooks/useMobileLayout';
import { colours } from 'src/styles/variables';
import hexToRgb from 'src/utils/hexToRgb';
import useFeatureFlag from 'src/components/feature-flags/useFeatureFlag';
import { useCreateEpisodeMutation } from 'src/generated/graphql';
import useCanCreateEpisode from 'src/pages/create-episode/hooks/useCanCreateEpisode';
import EpisodesFilter from './filter/EpisodesFilter';
import UpgradeSubscriptionModal from '../UpgradeSubscriptionModal';

export default function TopSection() {
  return (
    <PageHeaderContainer
      templateColumns="1fr auto"
      templateAreas={`"heading" "subheading"`}
      gridRowGap="1px"
      headerProps={{ paddingBottom: '0px' }}
    >
      <PageHeading gridArea="heading" pb="24px">
        My Episodes <Help />
      </PageHeading>
      <AddEpisodeButton />
      <EpisodesFilter />
    </PageHeaderContainer>
  );
}

function AddEpisodeButton() {
  const canCreateEpisode = useCanCreateEpisode();
  const createEpisode = useCreateEpisode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobileLayout = useMobileLayout();

  // Workaround for permissions not being fetched for some legacy users
  const { permissions, refetchPermissions } = usePermissionsContext();
  useEffect(() => {
    if (!permissions) {
      refetchPermissions();
    }
  }, [permissions, refetchPermissions]);

  switch (isMobileLayout) {
    case true: {
      return (
        <IconButton h="36px" w="36px" aria-label="create episode" onClick={() => createEpisode()}>
          <PlusIcon fill={hexToRgb(colours.white, 0.8)} />
        </IconButton>
      );
    }
    case false: {
      return (
        <>
          <UpgradeSubscriptionModal isOpen={isOpen} onClose={onClose} />
          <Button
            onClick={() => {
              if (!canCreateEpisode) {
                onOpen();
                return;
              }
              createEpisode();
            }}
            leftIcon={<PlusIcon fill={colours.white} />}
            h="48px"
            w="197px"
            justifySelf="end"
          >
            Add an episode
          </Button>
        </>
      );
    }
  }
}

export function useCreateEpisode() {
  const { pathname } = useLocation();
  const [createEpisodeFn] = useCreateEpisodeMutation({
    variables: { metaData: { name: `New Episode ${new Date().toLocaleString()}` } },
    onCompleted: ({ episode }) => {
      navigate(`/upload/${episode.id}`, {
        state: { status: 'noUploads', previousPathname: pathname },
      });
    },
  });
  const navigate = useNavigate();
  const skipFirstEpisodeCreationStep = useFeatureFlag('skip-first-episode-creation-step');

  if (skipFirstEpisodeCreationStep) {
    return createEpisodeFn;
  }

  return function createEpisode() {
    return navigate('/create-episode');
  };
}
