import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';
import ErrorFallback from 'src/components/error-fallback/ErrorFallbackModal';
import SidebarMenu from 'src/components/menu/sidebar/SidebarMenu';
import { useUserCache } from 'src/apollo/cache';
import useMobileLayout from 'src/hooks/useMobileLayout';
import MobileBottomNav from 'src/components/menu/mobile/MobileBottomNav';
import MobileTopNav from 'src/components/menu/mobile/MobileTopNav';
import { useMediaQuery, Box, Flex, Spinner } from '@chakra-ui/react';
import { colours } from 'src/styles/variables';
import { Tier } from 'src/generated/graphql';
import { isPage } from './helpers';
import { useMenuContext } from '../menu/MenuContext';
import { usePermissionsContext } from '../permissions/PermissionsContext';

interface PrivateRouteProps {
  component: FC;
  isFullscreen?: boolean;
}

export default function PrivateRoute({
  component: Component,
  isFullscreen = false,
  ...props
}: PrivateRouteProps) {
  const user = useUserCache();
  const [menuState] = useMenuContext();
  const isMobileLayout = useMobileLayout();
  const [isLessThan1028] = useMediaQuery('(max-width: 1028px)');

  const { permissions } = usePermissionsContext();

  // If the user has not finished signing up, take them to the signup page.
  if (permissions?.tier === Tier.TrialWithCardIncomplete) {
    return <Navigate to="/signup" />;
  }

  const canAccessPrivateRoutes =
    permissions?.tier !== Tier.None && permissions?.tier !== Tier.TrialWithoutCardExpired;

  if (
    !canAccessPrivateRoutes &&
    !isPage([
      '/no-subscription',
      '/my-account',
      '/success',
      '/upgrade',
      '/payment',
      '/payment/processing',
      '/renew',
    ])
  ) {
    return <Navigate to="/no-subscription" />;
  }

  if (
    !user?.isAuthenticated &&
    !isPage(['/episodes', '/library', '/publish']) &&
    window.location.pathname.includes('/episode/') === false &&
    window.location.pathname.includes('/audio-editor/') === false
  ) {
    return (
      <Flex>
        <SidebarMenu />
        <Box
          minHeight="100vh"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="xl" color="podcastPurple" speed="0.8s" />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex w="100%">
      {!isFullscreen && isMobileLayout === false && <SidebarMenu />}
      {isMobileLayout && <MobileTopNav />}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Box flexGrow={1}>
          <Component {...props} />
        </Box>
      </ErrorBoundary>
      {isMobileLayout && <MobileBottomNav />}
      {isLessThan1028 && menuState === 'open' && !isMobileLayout && (
        <Box
          zIndex="200"
          position="fixed"
          width="100%"
          height="100%"
          background={colours.white}
          opacity="70%"
          ml="260px"
        />
      )}
    </Flex>
  );
}
